import React, { useEffect, type ReactNode, useRef } from 'react'
// @ts-expect-error no declarations
import TOPOLOGY from 'vanta/dist/vanta.topology.min'
import { useWindowSize } from 'react-use'

import logo from './assets/proptrakx.png'

interface IVanta {
  resize: () => void
}

function App (): ReactNode {
  let animationRef = useRef<IVanta>(null)
  const { width, height } = useWindowSize()

  useEffect(() => {
    setupAnimation()
  }, [])

  useEffect(() => {
    animationRef.current?.resize()
  }, [width, height])

  const setupAnimation = (): void => {
    animationRef = TOPOLOGY({
      el: '#target',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: '#ccecff',
      backgroundColor: '#f9f9f9'
    })
  }

  return (
    <>
      <section className="w-full h-screen fixed z-10">
        <div className="mx-auto max-w-screen-md flex h-full">
          <div className="mx-auto py-8 px-4 lg:py-16 lg:px-12 flex items-center">
            <img src={logo} className="mx-auto opacity-100" alt='PropTrakx Logo' />
          </div>
        </div>
      </section>
      <div className="h-screen w-full" id="target"></div>
    </>
  )
}

export default App
